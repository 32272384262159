import {FaPython,FaJava   } from 'react-icons/fa';
import "./CreateProject.scss";
import { PiFileCppDuotone } from "react-icons/pi";
import io from 'socket.io-client';
import { useState } from 'react';
import { Socket } from 'socket.io-client';
import { useParams } from 'react-router-dom';

const socket = io('https://vstart-main-server.onrender.com/', {
    transports: ['websocket', 'polling'],
    withCredentials: true
  });

export const CreateProjectModal = ({closeModal}) => {
   
    const [projectName, setProjectName] = useState();
    const [language, setLanguage] = useState();
    const { projectRoom, profileName, startupId } = useParams();

    const createProjectFunc =() => {
        console.log(projectName, language)
        socket.emit("createproject", {startupId, projectName, language})
        window.location.reload();
    }
    return(
    <>
        <div className='modal-wrapper'></div>
        <div className='createProject-container'>
            <div className="dialog-container">
        <div className='left-container'>
        <div className="header-container">
        <h1>CREATE A PROJECT.</h1>
        </div>
        <div className="input-container">
            <p>Name:</p>
        <input type="text"  onChange={(e) => setProjectName(e.target.value)} value={projectName} required/>
        </div>
        <div className='button-container'>
        <h3>Wanna know more about Projects?</h3>
        <p>Check out this page to know more about Projects</p>
        <div className='all-buttons'>
        <div className='confirm-container'>
        <button onClick={() => {
            createProjectFunc()
            closeModal()
            }}>Make the Project</button>
        </div>
        <div className='cancel-container'>
        <button onClick={closeModal}>Cancel</button>
        </div>
        </div>
        </div>
        </div>
        <div className='right-container'>
        <div className='template-container'>
        <div className="file-container">
        <div className="title-container">
          <h2>Choose Your Starting Template</h2>
        </div>
        <div className="container-container">
        <span onClick={() => setLanguage("python")}><FaPython color='white' size={30}></FaPython><h3>Python</h3></span> 
        <span onClick={() => setLanguage("Java")}><FaJava color='white' size={30} /> <h3>Java</h3></span>
        <span  onClick={() => setLanguage("CPP")}><PiFileCppDuotone color='white' size={30} /><h3>C++</h3></span>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
    )
}