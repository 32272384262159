import {FaPython,FaJava   } from 'react-icons/fa';
import "./createFile.scss";
import { PiFileCppDuotone } from "react-icons/pi";
import io from 'socket.io-client';
import { useState } from 'react';
import { Toaster, toast } from "react-hot-toast";
import { Socket } from 'socket.io-client';
import { useParams } from 'react-router-dom';

const socket = io('https://vstart-main-server.onrender.com/', {
    transports: ['websocket', 'polling'],
    withCredentials: true
  });

export const CreateFileModal = ({closeModal}) => {
   
    const [fileName, setFileName] = useState();
    const [fileType, setFileType] = useState();
    const { profileName, startupId,  projectId } = useParams();
    const [isLoading, setIsLoading] = useState(false);



    const CreateFileButton = () => {
        if(fileType === "python") {
            const filePath = `${fileName}.py`
            console.log(filePath)
            const fileDir = `main-dir/${startupId}/${projectId}/${filePath}`
            socket.emit ("createFile", fileDir )
            closeModal()
            window.location.reload()

        } else if (fileType === "Java") {
            const filePath = `${fileName}.java`
            console.log(filePath)
            const fileDir = `main-dir/${startupId}/${projectId}/${filePath}`
            socket.emit ("createFile", fileDir )
            closeModal()
            window.location.reload()
        }else if (fileType === "CPP") {
            const filePath = `${fileName}.cpp`
            console.log(filePath)
            const fileDir = `main-dir/${startupId}/${projectId}/${filePath}`
            socket.emit ("createFile", fileDir )
            closeModal()
            window.location.reload()
        }else{
            toast.error("Choose a file Type!")
        }
    }


        return (
            <>
            <div className='modal-wrapper'></div>
            <div className='createProject-container'>
                <div className="dialog-container">
            <div className='left-container'>
            <div className="header-container">
            <h1>CREATE A FILE.</h1>
            </div>
            <div className="input-container">
                <p>Name:</p>
            <input type="text"  onChange={(e) => setFileName(e.target.value)} value={fileName} required/>
            </div>
            <div className='button-container'>
            <h3>Which files do we support?</h3>
            <p>Right Now, only C++, Java & Python but soon we will have a lot more</p>
            <div className='all-buttons'>
            <div className='confirm-container'>
            <button onClick={() => {
                // createProjectFunc()
                CreateFileButton()
                }}>Make the Project</button>
            </div>
            <div className='cancel-container'>
            <button onClick={closeModal}>Cancel</button>
            </div>
            </div>
            </div>
            </div>
            <div className='right-container'>
            <div className='template-container'>
            <div className="file-container">
            <div className="title-container">
              <h2>Choose Your File Type</h2>
            </div>
            <div className="container-container">
            <span onClick={() => setFileType("python")}><FaPython color='white' size={30}></FaPython><h3>Python</h3></span> 
            <span onClick={() => setFileType("Java")}><FaJava color='white' size={30} /> <h3>Java</h3></span>
            <span  onClick={() => setFileType("CPP")}><PiFileCppDuotone color='white' size={30} /><h3>C++</h3></span>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <Toaster />
            </>
        )
}