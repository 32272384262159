import {FaPython,FaJava   } from 'react-icons/fa';
import { PiFileCpp, PiFileCppDuotone } from "react-icons/pi";
import vlogo from "./vlogo-tp.png";
import "./ProjectRoom.scss";
import './Utility.scss'
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CreateProjectModal } from './Modals/CreateProject';
import { renameProjectModal } from './Modals/RenameProject';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const socket = io('https://vstart-main-server.onrender.com/', {
  transports: ['websocket', 'polling'],
  withCredentials: true
});



export const ProjectRoom = () => {
  const [directories, setDirectories] = useState([]);
  const { projectRoom, profileName, startupId } = useParams();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState();
  const [language, setLanguage] = useState("");
  const [showMore, setShowMore]  = useState(null);
  const startupavaconst = startupId.charAt(0).toUpperCase();
  const nameavaconst = profileName.charAt(0).toUpperCase();
  const [showCreate, setShowCreate] = useState(false);
  const [showRename, setShowRename] = useState(null);
  const [newName, setNewName] = useState();

  const closeModal = () => setShowCreate(false);
  const closeModalRename = () => setShowRename(false);


  useEffect(() => {

    const handleConnect = () => {
      console.log('Connected to server:', socket.id);
    };

    setTimeout(() => {
      socket.emit('fetchStartUpProjects', startupId);
    }, 2)

    const handleDirectories = (data) => {
      // console.log('Received directories:', data);
      setDirectories(data.map(dir => dir.split('/').slice(-2, -1)[0])); // Extracting the folder names
    };

    const handleError = (errorMessage) => {
      console.error('Socket error:', errorMessage);
      toast.error(errorMessage);
    };

    socket.on('connect', handleConnect);
    socket.on('directories', handleDirectories);
    socket.on('error', handleError);

    // Cleanup on component unmount
    return () => {
      socket.off('connect', handleConnect);
      socket.off('directories', handleDirectories);
      socket.off('error', handleError);
    };

    
  }, [startupId]);

  const navBack = () => {
    navigate(`/`)
  }

  const createProjectFunc = () => {
    // const filePath = `${startupId}/${projectName}`
    socket.emit("createProject", `${startupId}/${projectName}`)
    // openAddFile()
    }

    const navProject = (directories) => {
      console.log(directories)
      navigate(`/vcode/${profileName}/${startupId}/${directories}`)
    }

    const openDetails = () => {
      setShowMore(true)
    }

    const open = Boolean(showMore);
    const handleClick = (event) => {
      event.stopPropagation();
      setShowMore(event.currentTarget);
    };
    const handleClose = () => {
      setShowMore(null);
    };

    const openRename = Boolean(showRename);
    const handleClickRename = (event) => {
      event.stopPropagation();
      setShowRename(event.currentTarget);
    };
    const handleCloseRename = () => {
      setShowRename(null);
    };

    const dialogRef = useRef(null);


  // const dialogRef = useRef(null);

  // useEffect(() => {
  //   if (dialogRef.current && dialogRef.current.hasAttribute("open")) {
  //     dialogRef.current.close();
  //   }
  // }, []);

  // const openAddFile = () => {
  //   if(!dialogRef.current){
  //     return;
  //   }
  //   dialogRef.current.hasAttribute("open")
  //   ? dialogRef.current.close()
  //   : dialogRef.current.showModal()
  // }

  return (
    <div className="room-container">
      <div className="utility-container">
        <div className="utility-top-container">
            <img src={vlogo}></img>
        </div>
        <div className="utility-middle-container">
          <button className="utility-buttons" onClick={() => toast(" 💻Already In VCode!")}><span className="material-symbols-outlined ">terminal</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">forum</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">event_note</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">auto_stories</span></button>
        </div>
      </div>
      <div className="right-container">
      <div className="header-container">
        <div className="header-left-container">
          {/* <span className="material-symbols-outlined" onClick={navBack}>arrow_back_ios</span> */}
            <h1>VCode</h1> 
        </div>
        <div className="header-middle-container">
            <form class="form">
    <label for="search">
        <input class="input" type="text" required="" placeholder="Search" id="search" />
        <div class="fancy-bg"></div>
        <div class="search">
            <svg viewBox="0 0 24 24" aria-hidden="true" class="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
            </svg>
            
        </div>
    </label>
</form>
        </div>
        <div className="header-right-container">
            <div className="startupAvatar">
                {
                  <Tooltip title={startupId} placement="bottom">
                    <Stack>
                  {<Avatar >{startupavaconst}</Avatar>}
                </Stack>
                  </Tooltip>
                }
                <span className="material-symbols-outlined">arrow_drop_down</span>
            </div>
            <div className="profileAvatar">
                {<Tooltip placement= "bottom" title= {profileName}>
                <Stack>
                  <Avatar>{nameavaconst}</Avatar>
                </Stack>
                </Tooltip>}
            </div>
        </div>
      </div>
      <div className="content-container">
        <div className="content-container-header">
          <h1>PROJECTS.</h1>
          {/* <span className="material-symbols-outlined" onClick={() =>{
            openAddFile()
            toast("Adding Projects will come VERY SOON!",  {
              icon: '⌛',
              style: {
                borderRadius: '90px',
                background: '#333',
                color: '#fff',
              },
            })
          }}>add</span> */}
          <span className="material-symbols-outlined" onClick={() => {setShowCreate(true)}}>add</span>
        </div>
        <div className="card-container">
          <div className="single-card-container">
          {directories.length === 0 ? (
                <div className="error-container"><h1>Error 404 - No directories Found!</h1></div>            
          ) : (
            directories.map((directory, index) => {
              

              const handleDownload = () => {
                socket.emit('downloadProject', { startupId, projectId: directory });

        const zipData = [];
        socket.on('zipData', (chunk) => {
            zipData.push(chunk);
        });

        socket.on('zipEnd', () => {
            const blob = new Blob(zipData.map(chunk => Uint8Array.from(atob(chunk), c => c.charCodeAt(0))), { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${directory}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });

        socket.on('error', (errorMessage) => {
            console.error('Error downloading the project:', errorMessage);
        });

              }

              const navProject = () => {
              navigate(`/vcode/${profileName}/${startupId}/${directory}`)
              }

              const navProjectTab = () => {
                window.open(`http://localhost:3001/vcode/${profileName}/${startupId}/${directory}`)
                }

              
              const renameProject = () => {
                  const filePath = `main-dir/${startupId}/${directory}`
                socket.emit("renameProject", {filePath, newName})
              }

              const deleteProject = (event) => {
                console.log("data is here", directory)
                event.stopPropagation();
                const deleteFilePath = `main-dir/${startupId}/${directory}`
                socket.emit('deleteProject', deleteFilePath);
                window.location.reload();
              }

              const deleteProjectMenu = () => {
                const deleteFilePath = `main-dir/${startupId}/${directory}`
                socket.emit('deleteProject', deleteFilePath);
                window.location.reload();
                  }
              return(
                <div key={index} className="single-card-container">
                <div className="project-card" onClick={navProject}>
                <div className="project-card-left">
                  <span className="material-symbols-outlined">deployed_code</span>
                  <h3>{directory}</h3>
                </div>
                <div className="project-card-right">
                  <span className="material-symbols-outlined" onClick={deleteProject}>delete</span>
                  <span className="material-symbols-outlined" onClick={handleClick}>more_vert</span>
                </div>
                </div>
                <Menu
                 className="basic-menu"
                 anchorEl={showMore}
                 open={open}
                 onClose={handleClose}
                 MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                 <MenuItem onClick={() => {
                   handleClose()
                   navProject()
                   }}>Open</MenuItem>
                 <MenuItem onClick={() =>{
                  navProjectTab()
                  handleClose()
                 }}>Open In A New Tab</MenuItem>
                  {/* <MenuItem onClick={() => {
                    setShowRename(true)
                    handleClose() 
                    }}>Rename</MenuItem> */}
                 <MenuItem onClick={() => {
                  handleDownload()
                  handleClose()
                 }}>Download The Project</MenuItem>
                  <MenuItem onClick={() => {
                    handleClose()
                    deleteProjectMenu()
                  }}>Delete</MenuItem>
                </Menu>
                <Menu
                 className="basic-menu"
                 anchorEl={showRename}
                 open={openRename}
                 onClose={handleCloseRename}
                 MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div className='rename-menu-container'>
                  <MenuItem><input type='text' value={newName} onChange={(e) => setNewName(e.target.value)}/></MenuItem>
                  <MenuItem><button onClick={renameProject}>Rename</button></MenuItem>
                  </div>
                </Menu>
              </div>
              )
            })
          )}
          </div>
        </div>
      </div>
      </div>
      <Toaster containerStyle={{fontFamily: 'inter'}} />
       { showCreate && <CreateProjectModal closeModal={closeModal}/> }
       { showRename && <renameProjectModal /> }
    </div>
  );

 
}

export default ProjectRoom;
