import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import { Login } from './components/Login';
import { NewId } from './components/NewId';
import { EditorContainer } from './components/EditorContainer';
import { ProjectRoom } from './components/ProjectRoom';

function App() {
  return (
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} ></Route>
        <Route path='/vcode/:profileName/:startupId/:projectId' element={<EditorContainer />} />
        <Route path="/newId" element={<NewId />} />
        <Route path="/projectroom?/:profileName/:startupId" element={<ProjectRoom />} />
      </Routes>
      </BrowserRouter>
  );
}

export default App;
