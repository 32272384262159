import { useState } from 'react';
import './newId.scss'
import axios from 'axios';
import vlogo from './vlogo-tp.png'
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import toast, { Toaster }  from 'react-hot-toast';

const socket = io.connect("localhost:3000");
const SLUG_WORKS = ["car", "dog", "computer", "person", "inside", "word", "for", "please", "to", "cool", "open", "source","create","something", "impressive", "conjucated", "working", "somehow", "how", "grind", "string"];
const SERVICE_URL = "http://localhost:3001";
function getRandomSlug() {
    let slug = "";
    for (let i = 0; i < 3; i++) {
        slug += SLUG_WORKS[Math.floor(Math.random() * SLUG_WORKS.length)];
    }
    return slug;
}

export const NewId =  () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [startupId, setStartupId] = useState(getRandomSlug);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        socket.emit("createStartUp", startupId, (response) => {
            if (response.success) {
                toast.success("StartUp Id Generated! Kindly login Now!");
                navigate(`/`);
            } else {
                toast.error("There was an error creating the StartUp ID.");
            }
            setLoading(false);
        });
    };

    return(
    <div className="newId-container">
        <div className='form-container'>
            <div className='left-container'>
                <img src= {vlogo} /> 
            </div>
             < div className='right-container'>
                    <h1>Make Your StartUp Id!</h1>
                    <h2>Enter Your StartUp Name!</h2>
                    <form onSubmit={handleSubmit} > 
                    <div className='input-container'>
                    <input 
                    onChange={(e) => setStartupId(e.target.value)}
                    type="text"
                    placeholder="Here is your Id!"
                    value={startupId} required></input>
                    </div>
                    <div className='checkbox-container'>
                    <input type='checkbox' required/>
                    <p>VStart is currently in beta. You may encounter bugs. Please submit any issues you find. Your feedback helps us improve.
                    Thank you!<br></br>[Divyansh - VStart]</p>
                    </div>
                    <button>{loading ? "Creating ..." : "Create & Join!"}</button>
                    </form>
            </div>
        </div>
        <Toaster containerStyle={{fontFamily: 'inter'}}></Toaster>
    </div>
    )
}

// onSubmit={async () => {
//     setLoading(true);
//     socket.emit("createStartUp", startupId)
//     setLoading(false);
//     navigate(`/`); 
//     toast.success("StartUp Id Generated! Kindly login Now!")
//     console.log("Done", {startupId})}}