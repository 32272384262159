import {Editor} from "@monaco-editor/react";
import logo from './logo-closeup-tp.png';
import vlogo from "./vlogo-tp.png";
import './Editor.scss';
import './Utility.scss'
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import io from 'socket.io-client';
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CreateFileModal } from "./Modals/Project Modals/createFile";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



  const socket = io('https://vstart-main-server.onrender.com/', {
      transports: ['websocket', 'polling'],
      withCredentials: true
  });

  const editorOptions  = {
    wordWrap: 'on'
  }
 

  export const EditorContainer = () => {

    const getLanguageFromExtension = (filename) => {
      const extension = filename.split('.').pop();
      switch (extension) {
          case 'js':
              return 'javascript';
          case 'ts':
              return 'typescript';
          case 'html':
              return 'html';
          case 'css':
              return 'css';
          case 'json':
              return 'json';
          case 'xml':
              return 'xml';
          case 'md':
              return 'markdown';
          case 'py':
              return 'python';
          case 'java':
              return 'java';
          case 'c':
              return 'c';
          case 'cpp':
              return 'cpp';
          case 'cs':
              return 'csharp';
          // Add more mappings as needed
          default:
              return '';
      }
  };
  

      const navigate = useNavigate();

      const { profileName, startupId, projectId } = useParams();
      const [fileStructure, setFileStructure] = useState([]);
      const [code, setCode] = useState("");
      const [selectedFile, setSelectedFile] = useState("");
      const [theme, setTheme] = useState("vs-dark");
      const [dialogContent, setDialogContent] = useState(null);
      const [showModal, setShowModal] = useState(false);
      const startupavaconst = startupId.charAt(0).toUpperCase();
      const nameavaconst = profileName.charAt(0).toUpperCase();
      const [language, setLanguage] = useState("");
      const [showRename, setShowRename] = useState(null);
      const [newName, setNewName] = useState();

      const later = () => {
        toast("coming soon!")
      }

      const openRename = Boolean(showRename);
      const handleClickRename = (event) => {
        event.stopPropagation();
        setShowRename(event.currentTarget);
      };
      const handleCloseRename = () => {
        setShowRename(null);
      };

      useEffect(() => {

          const handleConnect = () => {
              console.log('Connected to server:', socket.id);
          };

          socket.emit('fetchProjectData', { startupId, projectId });

          const handleDir = (data) => {
              // console.log('fileDir:', data);
              const files = data.map(dir => dir.split('/').pop()); // Extracting the file names
              setFileStructure(files);
          };

          const handleError = (errorMessage) => {
              console.error('Socket error:', errorMessage);
          };

          socket.on('connect', handleConnect);
          socket.on('fileDir', handleDir);
          socket.on('error', handleError);

          return () => {
              socket.off('connect', handleConnect);
              socket.off('fileDir', handleDir);
              socket.off('error', handleError);
          };
      }, [startupId, projectId]);

      const saveCode = () => {
        if (selectedFile) {
          socket.emit('saveFileContent', { path: `${startupId}/${projectId}/${selectedFile}`, content: code });
          toast.success("Successfully Saved")
        }
      }
      
      const downloadFile = () => {
        if (selectedFile) {
          socket.emit('fetchFileContent', { path: `${startupId}/${projectId}/${selectedFile}` }, (data) => {
            const blob = new Blob([data], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = selectedFile;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          });
        }
      };

      const fetchFileContent = (fileName) => {
          const filePath = `main-dir/${startupId}/${projectId}/${fileName}`;
          // console.log(filePath, "recieved client side") // Construct the full file path
          socket.emit('fetchFileContent', { filePath }, (content) => {
              setCode(content);
              setSelectedFile(fileName);
              const language = getLanguageFromExtension(fileName);
        setLanguage(language);
              // console.log(content, "This is CONTENT")
          });
      };

      const navBack = () => {
          navigate(`/projectroom/${profileName}/${startupId}`)
      }

      const locked = () => {
        toast("This feature will come in the next update")
      }

      const closeModal = () => {return setShowModal(false)};
      
      return (
         <div className="full-editor-container">
      <div className="utility-container">
        <div className="utility-top-container">
            <img src={vlogo}></img>
        </div>
        <div className="utility-middle-container">
          <button className="utility-buttons" onClick={() => toast(" 💻Already In VCode!")}><span className="material-symbols-outlined">terminal</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">forum</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">event_note</span></button>
          <button className="utility-buttons" onClick={() => toast("Coming Soon!! :)")}><span className="material-symbols-outlined">auto_stories</span></button>
        </div>
      </div>
          <div className="header-stuff">
          <div className="right-top-container">
  <div className="right-top-left-container">
      <span className="material-symbols-outlined" onClick={navBack}>arrow_back_ios</span>
      <h3>{selectedFile}</h3>
  </div>
  <div className="header-middle-container">
<form class="form">
<label for="search">
<input class="input" type="text" required="" placeholder="Search" id="search" />
<div class="fancy-bg"></div>
<div class="search">
<svg viewBox="0 0 24 24" aria-hidden="true" class="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
<g>
<path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
</g>
</svg>

</div>
</label>
</form>
</div>
  <div className="right-top-right-container">
  <div className="startupAvatar">
{
<Tooltip title={startupId} placement="bottom">
<Stack>
{<Avatar >{startupavaconst}</Avatar>}
</Stack>
</Tooltip>
}
<span className="material-symbols-outlined">arrow_drop_down</span>
</div>
<div className="profileAvatar">
{<Tooltip placement= "bottom" title= {profileName}>
<Stack>
<Avatar>{nameavaconst}</Avatar>
</Stack>
</Tooltip>}
</div>
  </div>
</div>
          <div className="editor-container">
              <div className="left-container">
                <div className="left-top-container">
                  <h3>{projectId}</h3>
                 <div className="left-top-right-container">
                 <span className="material-symbols-outlined" onClick={() => setShowModal(true)}>note_add</span>
                 <span className="material-symbols-outlined" onClick={later}>create_new_folder</span>
                 </div>
                </div>
                <div className="left-bottom-container">
                <div className="card-container">
                {fileStructure.map((file, index) => {
                 
                 const deleteFile = (file) => {
                    const deleteFilePath = `main-dir/${startupId}/${projectId}/${file}`
                    const dir = deleteFilePath;// Debug log
                    socket.emit("deleteCode", dir)
                    window.location.reload();
                }

                const renameFile= () =>{
                    const filePath = `main-dir/${startupId}/${projectId}/${file}/`
                    const renamePath = `main-dir/${startupId}/${projectId}/${newName}/`
                    socket.emit("renameFile", {filePath, renamePath})
                }
                    
                 return(
                     <>
                        <div key={index} className="card" onClick={() => fetchFileContent(file)}>
                          <div className="card-left-container">
                          <span className="material-symbols-outlined">article</span>
                          <span>{file}</span>
                          </div>
                          <div className="card-right-container">
                            {/* <span className="material-symbols-outlined" onClick={handleClickRename}>edit</span> */}
                            <span className="material-symbols-outlined" onClick={() => deleteFile(file)}>delete</span>
                          </div>
                      </div>
                      <Menu
                 className="basic-menu"
                 anchorEl={showRename}
                 open={openRename}
                 onClose={handleCloseRename}
                 MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                    <div className="rename-menu">
                    <MenuItem><input type="text" value={newName} onChange={(e) => setNewName(e.target.value)}/></MenuItem>
                    <MenuItem><button onClick={() => {
                        handleCloseRename()
                        renameFile()
                    }}>Rename!</button></MenuItem>
                    </div>
                </Menu>
                      </>
                    )
                })}
                </div>
                </div>
              </div>
              <div className="right-container">
                  <div className="right-middle-container">
                      <Editor 
                          theme={theme}
                          language={language}
                          value={code}
                          options={editorOptions}
                          onChange={(newValue) => setCode(newValue)}
                      />
                  </div>
                  <div className="right-bottom-container">
                      <div className="right-bottom-left-container">
                          <span className="material-symbols-outlined" onClick={() => saveCode()}>save</span>
                          <span className="material-symbols-outlined"  onClick={() => downloadFile()}>download</span>
                      </div>
                      <div className="right-bottom-right-container">
                          <select name="Select Theme" onChange={(e) => setTheme(e.target.value)}>
                              <option value="vs-dark">vs dark</option>
                              <option value="vs-light">vs light</option>
                          </select>
                          <button onClick={locked}>RUN!</button>
                      </div>
                  </div>
              </div>
              <Toaster containerStyle={{fontFamily: 'inter'}} />
              {showModal && <CreateFileModal closeModal={closeModal} />}
          </div>
          </div>
         </div>
      );
  };

