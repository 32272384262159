import { useEffect, useState } from 'react';
import './login.scss';
import logo from "./logo-closeup-tp.png"
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import toast, { Toaster }  from 'react-hot-toast';

export const Login = () => {
    const [name, setName] = useState('');
    const [startupId, setStartupId] = useState('');
    const navigate = useNavigate();
    const navigateNewId = () => {
        navigate(`/newId`)
    }
    const socket = io.connect("localhost:3000");

    const navigateRoom = () => {
        // socket.emit("fetchStartUpProjects", startupId)
        navigate(`/projectroom/${name}/${startupId}`)
        
    }


    return (
        <div className="login-container">
            <div className="left-container">
            <img src={logo} />
            <h2>Version 0.1.0</h2>
            </div>
            <div className="right-container">
            <div className="content-container">
                <form>
            <div className='welcome-container'>
                <h2>WELCOME!</h2>
            </div>
            <div className='name-container'>
                Enter your Name Here
                <input  label="name" type='text'  placeholder="Your Name Here!" onChange={(e) => setName(e.target.value)} value={name} ></input>
            </div>
            <div className='id-container'>
                Enter Your VSpace Id!
                <input label="id"  type='text' placeholder="Enter the ID Here!" onChange={(e) => setStartupId(e.target.value)} value={startupId} required/>
            </div>
            <div className='forgot-container'>
                <span onClick={navigateNewId}>Dont Have One? Create it!</span>
            </div>
            <div className='submit-container'>
                    <button onClick={navigateRoom}>Join The StartUp!</button>
            </div>
            </form>
            </div>
            </div>
            <Toaster containerStyle={{fontFamily: 'inter'}} />
        </div>
    )
}